import React from "react";
import { Route } from "react-router-dom";
import { MDXPage } from "./MDXPage";

const MDXRoutes = (menu) => {
  return menu.map(({ submenu }) =>
    submenu.map((props) => {
      return (
        <Route
          key={props.path}
          path={props.path}
          element={props ? <MDXPage path={props.path} mdx={props.mdx} menu={menu} /> : <></>}
        />
      );
    }),
  );
};

export default MDXRoutes;
