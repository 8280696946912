import React from "react";
import { Link, useLocation } from "react-router-dom";
import PropTypes from "prop-types";
import { Box, Button } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { LazyLoadImage } from "react-lazy-load-image-component";
import NavItem from "./components/NavItem";
import { setChatVisibility } from "../../../../../../utils";

import lightLogo from "../../../../../../../assets/img/DittAndetagWhite.webp";
import darkLogo from "../../../../../../../assets/img/DittAndetagBlack.webp";

const SidebarNav = ({ pages, handleClose }) => {
  const theme = useTheme();
  const location = useLocation();
  const path = location.pathname;
  const { mode } = theme.palette;

  const menuPages = pages.flatMap((pageGroups) =>
    pageGroups.map((page) => ({
      title: page.title,
      submenu: page.submenu.map((subPage) => ({
        title: subPage?.title,
        href: subPage?.path,
      })),
    })),
  );

  return (
    <Box>
      <Box width={1} paddingX={2} paddingY={3.5}>
        <Box
          display={"flex"}
          component={Link}
          to="/"
          title="Ditt Andetag"
          width={{ xs: 100, md: 120 }}
          onClick={handleClose}
        >
          <Box
            component={LazyLoadImage}
            alt="DittAndetag Logo"
            src={mode === "light" ? darkLogo : lightLogo}
            height={1}
            width={1}
          />
        </Box>
      </Box>
      <Box paddingX={2} paddingY={2}>
        <Box>
          <NavItem title={"Start"} items={[]} pageLink="/#welcome" handleClose={handleClose} />
        </Box>

        {menuPages.map((page, pageI) => (
          <Box key={pageI}>
            <NavItem title={page.title} items={page.submenu} handleClose={handleClose} />
          </Box>
        ))}

        <Box marginTop={6}>
          <Button
            size={"large"}
            variant="outlined"
            fullWidth
            component={"a"}
            href="https://www.facebook.com/ditt.andetag/upcoming_hosted_events"
            target="_blank"
            sx={{ fontWeight: path === "/docs" ? 600 : 200 }}
            onClick={() => handleClose()}
          >
            Kommande Event
          </Button>
        </Box>
        <Box marginTop={3}>
          <Button
            size={"large"}
            variant="contained"
            color="primary"
            fullWidth
            component={Link}
            to="https://dittandetag.se"
            onClick={() => {
              setChatVisibility("visible");
              handleClose();
            }}
            sx={{ fontWeight: path === "/book-a-demo" ? 600 : 200 }}
          >
            Webbutiken
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

SidebarNav.propTypes = {
  pages: PropTypes.array.isRequired,
  handleClose: PropTypes.func.isRequired,
};

export default SidebarNav;
