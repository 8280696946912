import React from "react";
import { useTheme } from "@mui/material/styles";
import { Box, Typography, Card, Button, useMediaQuery } from "@mui/material";

export const Attributes = ({ children }) => {
  const theme = useTheme();
  const mobile = useMediaQuery("(max-width:800px)");

  return (
    <Box
      component={Card}
      boxShadow={0}
      border={`1px solid ${theme.palette.divider}`}
      sx={{
        marginTop: 0,
        marginBottom: 4,
        marginLeft: 2,
        width: mobile ? "100%" : "50%",
        float: "right",
      }}
    >
      <Box padding={{ xs: 2, sm: 3 }}>{children}</Box>
    </Box>
  );
};

export const Attribute = ({ title, value }) => {
  return (
    <>
      <Typography
        sx={{
          fontWeight: "700",
          display: "inline",
        }}
        gutterBottom
      >
        {title}:
      </Typography>
      <Typography
        variant={"body2"}
        color={"text.secondary"}
        sx={{
          marginBottom: 1,
          marginLeft: 1,
        }}
      >
        {value}
      </Typography>
    </>
  );
};

export const AttributeCTA = ({ title, href }) => {
  return (
    <>
      <Button
        component={"a"}
        variant="contained"
        color="primary"
        size="large"
        fullWidth={true}
        href={href}
      >
        {title}
      </Button>
    </>
  );
};
