import React from "react";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import { Box } from "@mui/material";
import { extractHash } from "../../../../../../utils";

const NavAHref = ({ title, href, target }) => {
  const path = location.pathname;

  return (
    <a href={href} target={target} rel="noreferrer">
      <Box
        color={"common.white"}
        sx={{
          "display": "flex",
          "alignItems": "center",
          "padding": "10px",
          "borderRadius": "10px",
          "&:hover": {
            backgroundColor: "#F3F6F9",
            color: "#3E5060",
            textShadow: "none",
          },
          "textDecoration": "none",
          "fontWeight": extractHash(href) === path ? 800 : 600,
          "color": "#ffffff",
          "textShadow": "#000000 0px 0px 3px",
        }}
      >
        {title}
      </Box>
    </a>
  );
};

const NavBox = ({ title, href }) => {
  const path = location.pathname;

  return (
    <Box
      component={Link}
      to={href}
      color={"common.white"}
      sx={{
        "display": "flex",
        "alignItems": "center",
        "padding": "10px",
        "borderRadius": "10px",
        "&:hover": {
          backgroundColor: "#F3F6F9",
          color: "#3E5060",
          textShadow: "none",
        },
        "textDecoration": "none",
        "fontWeight": extractHash(href) === path ? 800 : 600,
        "color": "#ffffff",
        "textShadow": "#000000 0px 0px 3px",
      }}
    >
      {title}
    </Box>
  );
};

const NavLink = ({ title, href, target }) => {
  return target ? (
    <NavAHref title={title} href={href} target={target} />
  ) : (
    <NavBox title={title} href={href} />
  );
};

NavLink.propTypes = {
  title: PropTypes.string.isRequired,
  href: PropTypes.string.isRequired,
  colorInvert: PropTypes.bool,
};

export default NavLink;
