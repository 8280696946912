import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import PropTypes from "prop-types";
import { alpha, useTheme } from "@mui/material/styles";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Grid,
  Box,
  Button,
  Typography,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { extractHash } from "../../../../../../../../utils";

const NavItem = ({ title, items, pageLink = "", handleClose = () => {} }) => {
  const theme = useTheme();
  const location = useLocation();
  const emptyPages = !items.length;
  const [activeLink, setActiveLink] = useState("");
  const [hasActiveLink, setHasActiveLink] = useState(false);

  useEffect(() => {
    setActiveLink(location.pathname);
    setHasActiveLink(items.find((i) => extractHash(i.href) === extractHash(location.pathname)));
  }, [location.pathname]);

  return (
    <Box>
      <Accordion disableGutters elevation={0} sx={{ backgroundColor: "transparent" }}>
        <AccordionSummary
          expandIcon={!emptyPages && <ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
          sx={{ padding: 0 }}
        >
          <Typography
            component={pageLink ? Link : Typography}
            to={pageLink}
            fontWeight={hasActiveLink || activeLink === extractHash(pageLink) ? 600 : 400}
            color={
              hasActiveLink || activeLink === extractHash(pageLink) ? "primary" : "text.primary"
            }
            sx={{ textDecoration: "none" }}
            onClick={() => pageLink && !items.length && handleClose()}
          >
            {title}
          </Typography>
        </AccordionSummary>
        <AccordionDetails sx={{ padding: 0 }}>
          <Grid container spacing={1}>
            {items.map((p, i) => {
              const regularLink = p.href === "/docs";
              return (
                <Grid item key={i} xs={12}>
                  <Button
                    size={"large"}
                    component={regularLink ? "a" : Link}
                    href={regularLink ? p.href : ""}
                    target={regularLink ? "_blank" : "_self"}
                    to={p.href}
                    fullWidth
                    onClick={handleClose}
                    sx={{
                      justifyContent: "flex-start",
                      color:
                        activeLink === extractHash(p.href)
                          ? theme.palette.primary.main
                          : theme.palette.text.primary,
                      backgroundColor:
                        activeLink === extractHash(p.href)
                          ? alpha(theme.palette.primary.main, 0.1)
                          : "transparent",
                      fontWeight: activeLink === extractHash(p.href) ? 600 : 400,
                    }}
                  >
                    {p.title}
                    {p.isNew && (
                      <Box
                        padding={0.5}
                        display={"inline-flex"}
                        borderRadius={1}
                        bgcolor={"primary.main"}
                        marginLeft={2}
                      >
                        <Typography
                          variant={"caption"}
                          sx={{ color: "common.white", lineHeight: 1 }}
                        >
                          new
                        </Typography>
                      </Box>
                    )}
                  </Button>
                </Grid>
              );
            })}
          </Grid>
        </AccordionDetails>
      </Accordion>
    </Box>
  );
};

NavItem.propTypes = {
  items: PropTypes.array.isRequired,
  title: PropTypes.string.isRequired,
  onClose: PropTypes.func,
  pageLink: PropTypes.string,
  handleClick: PropTypes.func,
};

export default NavItem;
