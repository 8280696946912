import React, { useState } from "react";
import { useTheme } from "@mui/material/styles";
import { Box, IconButton } from "@mui/material";
import { KeyboardArrowDown as KeyboardArrowDownIcon } from "@mui/icons-material";
import "./styles.css";

const checkpoint = 400;
const scrollHeight = 700;

const ScrollBelowArrow = () => {
  const theme = useTheme();
  const isDark = theme.palette.mode === "dark";
  const [opacity, setOpacity] = useState(1);

  window.addEventListener("scroll", () => {
    const currentScroll = window.pageYOffset;
    if (currentScroll <= checkpoint) {
      setOpacity(1 - currentScroll / checkpoint);
    } else {
      setOpacity(0);
    }
  });

  return (
    <Box
      id="scroll-down-arrow"
      sx={{
        position: "absolute",
        top: { xs: "85vh", md: "93vh" },
        marginLeft: "auto",
        marginRight: "auto",
        width: "100%",
        display: "flex",
        justifyContent: "center",
        zIndex: 25,
      }}
    >
      <IconButton
        aria-label="scroll down"
        onClick={() =>
          scrollBy({
            top: scrollHeight,
            behavior: "smooth",
          })
        }
        sx={{
          color: `rgba(${isDark ? "180,180,180" : "100,100,100"},${opacity})`,
          visibility: opacity <= 0 ? "hidden" : "",
        }}
      >
        <KeyboardArrowDownIcon sx={{ fontSize: 32, color: "inherit" }} />
      </IconButton>
    </Box>
  );
};

export default ScrollBelowArrow;
