import React from "react";
import {
  Box,
  Typography,
  Table,
  TableContainer,
  TableHead,
  TableRow,
  TableCell,
} from "@mui/material";
import { ProductImage } from "./ProductImage";
import { ShowcaseGrid, ShowcaseGridItem } from "./ShowcaseGrid/ShowcaseGrid";
import { Attributes, Attribute, AttributeCTA } from "./Attributes";
import { GretaHarperHeroVideo } from "./GretaHarperHeroVideo/GretaHarperHeroVideo";
import { ImageLeft, ImageRight } from "./Image";
import { ImageGrid, ImageItem } from "./ImageGrid";
import { Link, LinkBlank } from "./Link";
import { Events, Event } from "./Event";

export const mdxComponents = () => ({
  p: (props) => <Typography sx={{ mb: 3 }} {...props} />,
  img: ImageRight,
  table: (props) => (
    <TableContainer>
      <Table>{props.children}</Table>
    </TableContainer>
  ),
  thead: (props) => <TableHead>{props.children}</TableHead>,
  th: (props) => (
    <TableCell sx={{ whiteSpace: "nowrap", textAlign: "left", p: 1, fontWeight: "800" }}>
      {props.children}
    </TableCell>
  ),
  tr: (props) => <TableRow>{props.children}</TableRow>,
  td: (props) => <TableCell sx={{ p: 1 }}>{props.children}</TableCell>,

  a: Link,
  ul: (props) => <Box sx={{ mb: 3 }} {...props} />,
  li: (props) => (
    <Box
      sx={{
        paddingLeft: 1,
        marginLeft: 3,
        display: "list-item",
      }}
      {...props}
    />
  ),
  h1: (props) => (
    <Typography variant="h1" sx={{ lineHeight: 1.2, mb: { xs: 4, md: 2 } }} {...props} />
  ),
  h2: (props) => <Typography variant="h4" sx={{ mt: 6, mb: 1, fontWeight: 600 }} {...props} />,
  h3: (props) => <Typography variant="h6" sx={{ mb: 1, mt: 3, fontWeight: 600 }} {...props} />,
  Attribute,
  AttributeCTA,
  Attributes,
  GretaHarperHeroVideo,
  ProductImage,
  ShowcaseGrid,
  ShowcaseGridItem,
  ImageLeft,
  ImageRight,
  Link,
  LinkBlank,
  Events,
  Event,
  ImageGrid,
  ImageItem,
});
