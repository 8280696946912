import * as React from "react";
import { Box, Grid, Typography, Link, Chip } from "@mui/material";

export const ProductImage = ({ title, description, image, alt, button, href, chips }) => {
  const chipsArr = chips ? chips.split("|") : [];
  return (
    <Grid
      item
      xs={12}
      sm={6}
      md={5}
      marginLeft={{ xs: "0px", sm: "20px" }}
      marginRight="0px"
      style={{
        float: "right",
        marginBottom: "20px",
      }}
    >
      <Box width={1} height={1} position={"relative"}>
        <img
          src={image}
          alt={alt}
          loading="lazy"
          style={{
            filter: "brightness(0.7)",
            borderRadius: 8,
            width: "100%",
            height: "100%",
            objectFit: "cover",
          }}
        />
        <Box
          sx={{
            position: "absolute",
            top: 20,
            right: 20,
          }}
        >
          {chipsArr.map((label, id) => (
            <Chip
              key={id}
              label={label}
              component="a"
              size={"small"}
              sx={{
                marginBottom: 1,
                marginRight: 1,
                marginLeft: 1,
                bgcolor: "background.paper",
              }}
            />
          ))}
        </Box>

        <Box position={"absolute"} bottom={0} left={0} right={0} width={1} padding={2} zIndex={2}>
          <Typography color={"common.white"} fontWeight={700} variant={"h6"}>
            {title}
          </Typography>
          <Typography color={"common.white"}>{description}</Typography>
          {href && (
            <Link
              href={href}
              color={"common.white"}
              fontWeight={700}
              sx={{ marginTop: 2, display: "block" }}
            >
              {button || "Trigga min fantasi"}
            </Link>
          )}
        </Box>
      </Box>
    </Grid>
  );
};
