import React from "react";
import { Grid } from "@mui/material";
import "./skeleton.css";

function SkeletonPage() {
  const dummy = new Array(10).fill("");
  return (
    <Grid container spacing={3}>
      <Grid item md={6} xs={12}>
        <div className="skeleton skeletonHeading"></div>
        <div data-body>
          {dummy.map((x, i) => (
            <div key={i} className="skeleton skeletonText" />
          ))}
        </div>
        <div data-body>
          {dummy.map((x, i) => (
            <div key={i} className="skeleton skeletonText" />
          ))}
        </div>
      </Grid>
      <Grid item md={6} xs={12}></Grid>
    </Grid>
  );
}

export default SkeletonPage;
