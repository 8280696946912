import React from "react";
import { useTheme } from "@mui/material/styles";
import { Box, Typography } from "@mui/material";
import PropTypes from "prop-types";

const HeaderText = ({ title, subtitle, currentPage = "", children = "" }) => {
  const theme = useTheme();

  return (
    <Box sx={{ position: "relative", bottom: { xs: 20, md: 60 } }}>
      <Box marginBottom={1}>
        <Typography variant="h3" sx={{ fontWeight: 700 }}>
          <Typography
            component={"span"}
            variant={"inherit"}
            color={"secondary"}
            gutterBottom
            sx={{
              fontWeight: "medium",
              textDecoration: "none",
              fontSize: { xs: 15, md: 15, lg: 18 },
              display: "block",
              margin: 0,
            }}
          >
            {currentPage}
          </Typography>
          <Typography
            component={"span"}
            variant={"inherit"}
            color={theme.palette.text.hero}
            sx={{
              fontSize: { xs: 18, md: 25, lg: 30 },
              display: "block",
              margin: 0,
            }}
          >
            {title}
          </Typography>
          <Typography
            component={"span"}
            variant={"inherit"}
            sx={{
              color: "#3399ff",
              fontSize: { xs: 30, md: 35, lg: 40 },
              display: "block",
              margin: 0,
              lineHeight: 1,
            }}
          >
            {subtitle}
          </Typography>
        </Typography>
      </Box>
      <Box>
        <Typography
          variant="h6"
          component="p"
          color={theme.palette.text.hero}
          sx={{ maxWidth: { xs: "100%", md: "50%" } }}
        >
          {children}
        </Typography>
      </Box>
    </Box>
  );
};

HeaderText.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.node,
  children: PropTypes.node,
  currentPage: PropTypes.string,
  subtitleColor: PropTypes.string,
  titleColor: PropTypes.string,
};

export default HeaderText;
