// eslint-disable-next-line no-unused-vars
export const setChatVisibility = (visibility) => {
  // TODO: We handle it with EVENT block
  // const chatContainer = document.querySelector('.--harper-chatBot-container');
  // if (chatContainer) chatContainer.style.visibility = visibility;
};

export const scrollToTop = () => {
  window.scrollTo({
    top: 0,
    behavior: "smooth",
  });
};

export const extractHash = (link) =>
  link.includes("#") ? link.substring(0, link.lastIndexOf("#")) : link;
