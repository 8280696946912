import { MDXRoutes } from "../../mdxComponents";

const Event = () => import("./event.mdx");
const OmOss = () => import("./om-oss.mdx");
const PersonligService = () => import("./personlig-service.mdx");
const IButiken = () => import("./i-butiken.mdx");
const IMedia = () => import("./i-media.mdx");
const CookiePolicy = () => import("./cookie-policy.mdx");
const TermsOfUse = () => import("./terms-of-use.mdx");

export const menu = [
  {
    title: "DittAndetag",
    submenu: [
      { path: "/information/event/", title: "Spirituella Event", mdx: Event },
      {
        path: "/information/personlig-service/",
        title: "Personlig Service",
        mdx: PersonligService,
      },
      { path: "/information/i-butiken/", title: "I Butiken", mdx: IButiken },
      { path: "/information/i-media/", title: "I Media", mdx: IMedia },
      { path: "/information/om-dittandetag/", title: "Om DittAndetag", mdx: OmOss },
    ],
  },
  {
    title: "Juridik",
    submenu: [
      { path: "/legal/cookie-policy/", title: "Cookie Policy", mdx: CookiePolicy },
      { path: "/legal/terms-of-use/", title: "Villkor och info", mdx: TermsOfUse },
    ],
  },
];

export const InformationRoutes = () => {
  return MDXRoutes(menu);
};
