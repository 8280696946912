import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { ThemeProvider } from "@mui/material/styles";
import { Paper, CssBaseline } from "@mui/material";
import getTheme from "../../theme";

const useDarkMode = () => {
  const [themeMode, setTheme] = useState("dark");
  const [mountedComponent, setMountedComponent] = useState(false);

  const setMode = (mode) => {
    try {
      window.localStorage.setItem("themeMode", mode);
    } catch {
      /* do nothing */
    }

    setTheme(mode);
  };

  const themeToggler = () => {
    if (themeMode === "light") {
      setMode("dark");
    } else {
      setMode("light");
    }
  };

  useEffect(() => {
    try {
      const localTheme = window.localStorage.getItem("themeMode");
      if (localTheme) {
        setTheme(localTheme);
      } else {
        // The default value if none is set
        setMode("light");
      }
    } catch {
      setMode("light");
    }

    setMountedComponent(true);
  }, []);

  return [themeMode, themeToggler, mountedComponent];
};

const Page = ({ children }) => {
  const [themeMode, themeToggler] = useDarkMode();

  return (
    <ThemeProvider theme={getTheme(themeMode, themeToggler)}>
      {/* CssBaseline kickstart an elegant, consistent, and simple baseline to build upon. */}
      <CssBaseline />
      <Paper elevation={0}>{children}</Paper>
    </ThemeProvider>
  );
};

Page.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Page;
