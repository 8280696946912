import React, { lazy, Suspense, useEffect, useRef } from "react";
import { Routes as ReactRoutes, Route, Navigate, useLocation, useNavigate } from "react-router-dom";
import Main from "./layouts/Main";
import Loading from "./layouts/Main/components/Loading";
import CookieConsent from "./components/CookieConsent";
import ScrollToTop from "./components/ScrollToTop";
import { InformationRoutes } from "./pages/Information";
import { KristallRoutes } from "./pages/Kristaller";

const ContactUs = lazy(() => import("./pages/About/ContactUs/ContactUs"));
const NotFound = lazy(() => import("./pages/NotFound/NotFound"));

const Routes = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const once = useRef(false);

  useEffect(() => {
    if (location.pathname === "/") {
      if (!once.current) {
        const chatBot = document.querySelector("#chat");
        chatBot.style.opacity = 0;
        setTimeout(() => {
          chatBot.style.opacity = 1;
        }, 4000);
        document.addEventListener(
          "readystatechange",
          () => {
            setTimeout(() => {
              chatBot.style.opacity = 1;
            }, 1000);
          },
          { once: true },
        );
      }
      navigate("/#welcome");
    }
    once.current = true;
  }, [location.hash, location.pathname]);
  return (
    <ScrollToTop>
      <ReactRoutes>
        <Route path={"/"} element={<></>}></Route>
        <Route element={<CookieConsent />}>
          <Route
            element={
              <Suspense fallback={<Loading />}>
                <Main />
              </Suspense>
            }
          >
            <Route index element={<></>}></Route>
            <Route path="/about/contact-us/" element={<ContactUs />} />
            <Route
              path="/not-found/"
              element={
                <Suspense fallback={<Loading />}>
                  <NotFound />
                </Suspense>
              }
            />
            {InformationRoutes()}
            {KristallRoutes()}
            <Route path="*" element={<Navigate to="/not-found" replace />} />
          </Route>
        </Route>
      </ReactRoutes>
    </ScrollToTop>
  );
};

export default Routes;
