import React, { useState, useEffect } from "react";
import { Outlet, Link } from "react-router-dom";
import { Button, Typography } from "@mui/material";
import { getCookie, setCookie } from "../../utils";
import { SnackbarAlert } from "..";

const CookieConsent = () => {
  const [open, setOpen] = useState(false);

  useEffect(() => {
    const accepted = getCookie("cookie-accepted");
    if (!accepted) {
      return setOpen(true);
    }
    return undefined;
  }, []);

  const handleClose = () => {
    setCookie("cookie-accepted", true, 360);
    setOpen(false);
  };

  const action = (
    <>
      <Typography variant="subtitle2" pb={{ xs: 2, sm: 1, md: 0 }}>
        Vi använder cookies för att säkerställa att du får den bästa upplevelsen. Läs mer i vå{" "}
        <Typography
          component={Link}
          to="/legal/cookie-policy/"
          color="error"
          sx={{ textDecoration: "none" }}
        >
          cookie policy
        </Typography>
        .
      </Typography>
      <Button
        color="success"
        size="medium"
        variant="contained"
        sx={{ mx: 2 }}
        onClick={handleClose}
      >
        Ok
      </Button>
    </>
  );

  return (
    <div>
      <SnackbarAlert severity="error" isOpen={open} action={action} handleClose={() => null} />
      <Outlet />
    </div>
  );
};

export default CookieConsent;
