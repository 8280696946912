import React from "react";
import PropTypes from "prop-types";
import { Box } from "@mui/material";

const Section = ({ children, ...rest }) => (
  <Box
    maxWidth={{ sm: 720, md: 1236 }}
    width={1}
    paddingX={2}
    paddingY={{ xs: 4, sm: 6, md: 8 }}
    minHeight={"70vh"}
    display={"flex"}
    margin={"auto"}
    alignItems={"center"}
    position={"relative"}
    {...rest}
  >
    {children}
  </Box>
);

Section.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Section;
