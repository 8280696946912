import * as React from "react";
import { useMediaQuery } from "@mui/material";

export const ImageLeft = (props) => {
  const mobile = useMediaQuery("(max-width:800px)");
  return (
    <img
      width={mobile ? "100%" : "50%"}
      style={{
        borderRadius: 8,
        float: "left",
        marginRight: "20px",
        marginBottom: "20px",
      }}
      {...props}
    />
  );
};

export const ImageRight = (props) => {
  const mobile = useMediaQuery("(max-width:800px)");
  return (
    <img
      width={mobile ? "100%" : "50%"}
      style={{
        borderRadius: 8,
        float: "right",
        marginLeft: "20px",
        marginBottom: "20px",
      }}
      {...props}
    />
  );
};
