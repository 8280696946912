import { MDXRoutes } from "../../mdxComponents";

const AndligUtveckling = () => import("./andlig-utveckling/andlig-utveckling.mdx");
const Ritualer = () => import("./andlig-utveckling/ritualer.mdx");
const Egenskaper = () => import("./andlig-utveckling/egenskaper.mdx");
const Affirmationer = () => import("./andlig-utveckling/affirmationer.mdx");
const Kristaller = () => import("./andlig-utveckling/kristaller.mdx");
const Placeringar = () => import("./andlig-utveckling/placeringar.mdx");
const Altare = () => import("./andlig-utveckling/altare.mdx");
const Skotselrad = () => import("./andlig-utveckling/skotselrad.mdx");
const Armbandsritualer = () => import("./ritualer/armbandsritualer.mdx");
const Morgonkarleksmeditation = () => import("./ritualer/morgonkarleksmeditation.mdx");
const InreHarmoni = () => import("./ritualer/inre-harmoni.mdx");
const Konflikter = () => import("./ritualer/konflikter.mdx");
const Karlek = () => import("./egenskaper/karlek.mdx");
const Kvarts = () => import("./kristaller/kvarts.mdx");
const Amazonit = () => import("./kristaller/amazonit.mdx");
const GronAventurin = () => import("./kristaller/gron-aventurin.mdx");
const Karneol = () => import("./kristaller/karneol.mdx");
const Lepidolit = () => import("./kristaller/lepidolit.mdx");
const Mansten = () => import("./kristaller/mansten.mdx");
const Rosenkvarts = () => import("./kristaller/rosenkvarts.mdx");

export const menu = [
  {
    title: "Andlig utveckling",
    submenu: [
      { path: "/kristaller/andlig-utveckling/", title: "Inledning", mdx: AndligUtveckling },
      { path: "/kristaller/egenskaper/", title: "Egenskaper", mdx: Egenskaper },
      { path: "/kristaller/kristaller/", title: "Kristaller", mdx: Kristaller },
      { path: "/kristaller/affirmationer/", title: "Affirmationer", mdx: Affirmationer },
      { path: "/kristaller/ritualer/", title: "Ritualer", mdx: Ritualer },
      { path: "/kristaller/altare/", title: "Altare", mdx: Altare },
      { path: "/kristaller/placeringar/", title: "Placeringar", mdx: Placeringar },
      { path: "/kristaller/skotselrad/", title: "Skötselråd", mdx: Skotselrad },
    ],
  },
  {
    title: "Egenskaper",
    submenu: [{ path: "/kristaller/karlek/", title: "Kärlek", mdx: Karlek }],
  },
  {
    title: "Ritualer",
    submenu: [
      {
        path: "/kristaller/armbandsritualer/",
        title: "Armbandsritualer",
        mdx: Armbandsritualer,
      },
      {
        path: "/kristaller/morgonkarleksmeditation/",
        title: "Morgonkärlek",
        mdx: Morgonkarleksmeditation,
      },
      { path: "/kristaller/inre-harmoni/", title: "Inre armoni", mdx: InreHarmoni },
      { path: "/kristaller/konflikter/", title: "Konfliktlösning", mdx: Konflikter },
    ],
  },
  {
    title: "Kristaller",
    submenu: [
      /* { title: "Ametist", path: "/kristaller/ametist/" },
      { title: "Apatit", path: "/kristaller/apatit/" },
      { title: "Apriko", path: "/kristaller/apriko/" },
      { title: "Aprikosagat", path: "/kristaller/aprikosagat/" },
      { title: "Aragonit", path: "/kristaller/aragonit/" },
      { title: "Bergkristall", path: "/kristaller/bergkristall/" },
      { title: "Bergskristall", path: "/kristaller/bergskristall/" },
      { title: "Blå Apatit", path: "/kristaller/blå_apatit/" },
      { title: "Blå Kalcit", path: "/kristaller/blå_kalcit/" },
      { title: "Blomsteragat", path: "/kristaller/blomsteragat/" },
      { title: "Botswana agat", path: "/kristaller/botswana_agat/" },

      { title: "Fluorit", path: "/kristaller/fluorit/" },
      { title: "Golden Healer", path: "/kristaller/golden_healer/" },
      { title: "Hematit", path: "/kristaller/hematit/" },
      { title: "Jade", path: "/kristaller/jade/" },
      { title: "Labradorit", path: "/kristaller/labradorit/" },
      { title: "Lapis Lazuli", path: "/kristaller/lapis_lazuli/" },
      { title: "Lapis", path: "/kristaller/lapis/" },
      { title: "Mookait", path: "/kristaller/mookait/" },
      { title: "Orange Kalcit", path: "/kristaller/orange_kalcit/" },
      { title: "Orca", path: "/kristaller/orca/" },
      { title: "Peach Moonstone", path: "/kristaller/peach_moonstone/" },
      { title: "Rökkvarts", path: "/kristaller/rökkvarts/" },
      { title: "Rosa opal", path: "/kristaller/rosa_opal/" },
      { title: "Selenit", path: "/kristaller/selenit/" },
      { title: "Sodalit", path: "/kristaller/sodalit/" },
      { title: "Tigeröga", path: "/kristaller/tigeröga/" }, */
      { path: "/kristaller/amazonit/", title: "Amazonit", mdx: Amazonit },
      { path: "/kristaller/grona-venturin/", title: "Grön Aventurin", mdx: GronAventurin },
      { path: "/kristaller/karneol/", title: "Karneol", mdx: Karneol },
      { path: "/kristaller/lepidolit/", title: "Lepidolit", mdx: Lepidolit },
      { path: "/kristaller/mansten/", title: "Månsten", mdx: Mansten },
      { path: "/kristaller/rosenkvarts/", title: "Rosenkvarts", mdx: Rosenkvarts },
      { path: "/kristaller/kvarts/", title: "Kvarts", mdx: Kvarts },
    ],
  },
];

export const KristallRoutes = () => {
  return MDXRoutes(menu);
};
