import React, { useState, useTransition } from "react";
import { HashLink } from "react-router-hash-link";
import { useTheme } from "@mui/material/styles";
import { Box, Grid, List, ListItem, ListItemText, ListSubheader, Divider } from "@mui/material";

const SidebarButton = ({ title, path }) => {
  const theme = useTheme();
  const isDark = theme.palette.mode === "dark";

  const [isPending, startTransition] = useTransition();
  const hoverColor = isDark ? "#ffffff" : "#000000";
  const active = window.location.toString();

  const [toValue, setToValue] = useState(path);

  // Define an onClick handler that updates the 'to' value
  const handleClick = () => {
    startTransition(() => {
      setToValue(path);
    });
  };

  return (
    <ListItem
      component={HashLink}
      to={toValue}
      onClick={handleClick}
      scroll={(el) => el.scrollIntoView({ behavior: "smooth", block: "start" })}
      sx={{
        "color": active.includes(path) ? "text.primary" : "text.secondary",
        "&:hover": {
          color: active.includes(path) ? "text.primary" : hoverColor,
        },
        "ml": 2,
      }}
    >
      <ListItemText primary={title} />
    </ListItem>
  );
};

const Sidebar = ({ pages }) => {
  const theme = useTheme();
  const isDark = theme.palette.mode === "dark";

  return pages.map((page, pageI) => (
    <Box key={pageI}>
      <List
        subheader={
          <ListSubheader
            component="div"
            sx={{
              color: isDark ? "#ffffff" : "#000000",
              fontSize: 16,
              fontWeight: 900,
              backgroundColor: "transparent",
              position: "relative",
            }}
          >
            {page.title}
          </ListSubheader>
        }
      >
        {page?.submenu?.map((v, i) => {
          const { path } = v;
          const title = v?.title || v.title;
          return <SidebarButton key={path} title={title} path={path} />;
        })}
      </List>
    </Box>
  ));
};

const DocumentView = ({ title, pages, children }) => {
  const theme = useTheme();

  return (
    <Box>
      <Grid container spacing={1}>
        <Grid item xs={12} md={3} display={{ xs: "none", md: "block" }}>
          <Box position={"sticky"} top={theme.spacing(10)} className={"sticky"}>
            <Sidebar title={title} pages={pages} />
            <Divider sx={{ mt: 3, mb: 5, maxWidth: { md: 200, xs: "100%" } }} />
          </Box>
        </Grid>
        <Grid item sm={12} md={9}>
          {children}
        </Grid>
      </Grid>
    </Box>
  );
};

export default DocumentView;
