import React from "react";
import { useLocation } from "react-router-dom";
import { useTheme } from "@mui/material/styles";
import { Box } from "@mui/material";
import { LazyLoadComponent } from "react-lazy-load-image-component";
import ScrollBelowArrow from "../ScrollBelowArrow";
import CloudImg from "./images/cloud.jpg";

const HeroVideo = ({ children, videoSrc, heroMedia = "" }) => {
  const theme = useTheme();
  const isDark = theme.palette.mode === "dark";
  const location = useLocation();
  const isFirstPage = location.pathname === "/";

  return (
    <>
      <ScrollBelowArrow />
      <Box
        id={isFirstPage ? "HeroVideo" : "hero-video-container"}
        position={"relative"}
        display={"flex"}
        alignItems={"center"}
        marginTop={0}
        paddingTop={0}
        height={{ xs: "85vh", md: "93vh" }}
      >
        {heroMedia ? (
          <Box
            id="hero-media"
            sx={{
              position: "absolute",
              top: 0,
              left: 0,
              width: "100%",
              height: "100%",
              objectFit: "cover",
              backgroundColor: isDark ? "#000000" : "#dcdcdc",
            }}
          >
            {heroMedia}
          </Box>
        ) : null}
        {videoSrc && (
          <Box
            id="video"
            sx={{
              position: "absolute",
              top: 0,
              left: 0,
              width: "100%",
              height: "100%",
              objectFit: "cover",
              backgroundColor: theme.palette.background.default,
            }}
          >
            <LazyLoadComponent>
              <video
                id="hero-video"
                loop={true}
                autoPlay={true}
                muted
                style={{ width: "100%", height: "100%", objectFit: "cover" }}
                preload="none"
                poster={CloudImg}
              >
                <source src={videoSrc} type="video/mp4" />
                Your browser does not support the video tag.
              </video>
            </LazyLoadComponent>
          </Box>
        )}

        <Box
          id="text"
          maxWidth={{ sm: 720, md: 1236 }}
          width={1}
          margin={"0 auto"}
          paddingX={10}
          paddingTop={15}
        >
          {children}
        </Box>
      </Box>
      {/* The curve at the bottom of the video */}
      <Box
        id="curve"
        sx={{
          position: "absolute",
          left: 0,
          right: 0,
          top: { xs: "78vh", md: "86vh" },
          height: "10vh",
          width: "100%",
          zIndex: 20,
        }}
      >
        <Box
          component={"svg"}
          preserveAspectRatio="none"
          xmlns="http://www.w3.org/2000/svg"
          x="0px"
          y="0px"
          viewBox="0 0 1920 100.1"
          sx={{
            position: "absolute",
            bottom: 0,
            width: "100%",
            height: "100%",
          }}
        >
          <path
            fill={theme.palette.background.default}
            d="M0,0c0,0,934.4,125,1920,0v100.1H0L0,0z"
          ></path>
        </Box>
      </Box>
    </>
  );
};

export default HeroVideo;
