import React from "react";
import { BrowserRouter } from "react-router-dom";
import Routes from "./Routes";
import Page from "./components/Page";

import "react-lazy-load-image-component/src/effects/blur.css";

const App = () => {
  return (
    <Page>
      <BrowserRouter>
        <Routes />
      </BrowserRouter>
    </Page>
  );
};

export default App;
