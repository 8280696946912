import React, { Suspense, lazy, useState, useEffect } from "react";
import { useTheme } from "@mui/material/styles";
import { Box } from "@mui/material";
import { MDXProvider } from "@mdx-js/react";
import { setCanonicalLink } from "../../utils/header";
import {
  DocumentView,
  Container,
  CurveSection,
  Hero,
  HeaderText,
  SkeletonPage,
} from "../../components";
import { mdxComponents } from "../mdxComponents";
import DefaultBackgroundImg from "./Images/alex-perez-ioJBsYQ-pPM-unsplash.jpg";

const MDXPage = ({ path, mdx, menu }) => {
  const theme = useTheme();
  const Component = lazy(mdx);
  setCanonicalLink(path);

  const [frontMatter, setFrontMatter] = useState({});

  useEffect(() => {
    async function fetchFrontMatter() {
      // The mdx is imported both here and by lazy, above.
      const fm = (await mdx()).frontmatter;
      setFrontMatter(fm);
      document.title = fm?.subTitle || ` ${fm?.title}` || "";
    }

    fetchFrontMatter();
  }, [path]);

  return (
    <>
      <Hero backgroundImage={`url(${frontMatter?.backgroundImage || DefaultBackgroundImg})`}>
        <HeaderText
          title={frontMatter?.title}
          subtitle={frontMatter?.subTitle}
          currentPage={frontMatter?.section}
          subtitleColor="common.white"
          titleColor="#dcdcdc"
        />
      </Hero>
      <Box
        position={"relative"}
        sx={{
          paddingTop: 4,
          backgroundColor: theme.palette.alternate.main,
          backgroundImage: `linear-gradient(120deg, ${theme.palette.background.paper} 0%, ${theme.palette.alternate.main} 100%)`,
        }}
      >
        <Container>
          <DocumentView pages={menu}>
            <MDXProvider arial-label="mdx-content" components={mdxComponents()}>
              <Suspense fallback={<SkeletonPage />}>
                <Component />
              </Suspense>
            </MDXProvider>
          </DocumentView>
        </Container>
        <CurveSection />
      </Box>
    </>
  );
};

export default MDXPage;
