import { responsiveFontSizes } from "@mui/material";
import { createTheme } from "@mui/material/styles";
import shadows from "./shadows";
import { light, dark } from "./palette";

const getTheme = (mode, themeToggler) =>
  responsiveFontSizes(
    createTheme({
      palette: mode === "dark" ? dark : light,
      shadows: shadows(mode),
      typography: {
        button: {
          fontWeight: 400,
          textTransform: "none",
        },
        fontFamily: "Inter, sans-serif",
        body1: {
          fontSize: "1rem",
          fontWeight: 400,
          lineHeight: 1.5,
        },
        body2: {
          fontSize: "0.875rem",
          fontWeight: 400,
          lineHeight: 1.57,
        },
        subtitle1: {
          fontSize: "1rem",
          fontWeight: 500,
          lineHeight: 1.75,
        },
        subtitle2: {
          fontSize: "0.875rem",
          fontWeight: 500,
          lineHeight: 1.57,
        },
        overline: {
          fontSize: "0.75rem",
          fontWeight: 300,
          letterSpacing: "0.5px",
          lineHeight: 2.5,
          textTransform: "uppercase",
        },
        caption: {
          fontSize: "0.75rem",
          fontWeight: 400,
          lineHeight: 1.66,
        },
        h1: {
          fontWeight: 700,
          fontSize: "3.5rem",
          lineHeight: 1.375,
        },
        h2: {
          fontWeight: 700,
          fontSize: "3rem",
          lineHeight: 1.375,
        },
        h3: {
          fontWeight: 700,
          fontSize: "2.7rem",
          lineHeight: 1.375,
        },
        h4: {
          fontWeight: 700,
          fontSize: "2rem",
          lineHeight: 1.375,
        },
        h5: {
          fontWeight: 300,
          fontSize: "1.5rem",
          lineHeight: 1.375,
        },
        h6: {
          fontWeight: 300,
          fontSize: "1.3rem",
          lineHeight: 1.375,
        },
      },
      zIndex: {
        appBar: 1200,
        drawer: 1300,
      },
      components: {
        MuiButton: {
          styleOverrides: {
            root: {
              fontWeight: 400,
              borderRadius: 5,
            },
            containedSecondary: mode === "light" ? { color: "white" } : {},
          },
        },
        MuiInputBase: {
          styleOverrides: {
            root: {
              borderRadius: 5,
            },
          },
        },
        MuiOutlinedInput: {
          styleOverrides: {
            root: {
              borderRadius: 5,
            },
            input: {
              borderRadius: 5,
            },
          },
        },
        MuiCard: {
          styleOverrides: {
            root: {
              borderRadius: 8,
            },
          },
        },
      },
      themeToggler,
    }),
  );

export default getTheme;
