import * as React from "react";
import { Box, Grid, Typography, Card, Chip, CardMedia, Button } from "@mui/material";

export const ImageGrid = ({ children }) => {
  return (
    <Grid container spacing={4}>
      {children}
    </Grid>
  );
};

export const ImageItem = ({
  title,
  subtitle,
  image,
  leftChip,
  rightChip,
  leftButtonTitle,
  leftButtonUrl,
  rightButtonTitle,
  rightBbuttonUrl,
}) => {
  return (
    <Grid item xs={12} sm={6} md={6} lg={6} sx={{ margin: "0px" }}>
      <Box
        display={"block"}
        width={1}
        height={1}
        sx={{
          "textDecoration": "none",
          "transition": "all .2s ease-in-out",
          "transform": "translateY(0px)",
          "&:hover": {
            transform: "translateY(-4px)",
          },
        }}
      >
        <Box
          component={Card}
          padding={0}
          width={1}
          height={1}
          display={"flex"}
          flexDirection={"column"}
        >
          <CardMedia
            title={subtitle}
            image={image}
            sx={{
              marginBottom: "20px",
              position: "relative",
              height: 320,
              overflow: "hidden",
            }}
          >
            {leftChip ? (
              <Box position={"absolute"} top={5} left={5} padding={1}>
                <Chip label={leftChip.split(",")[0].trim()} color={"secondary"} />
              </Box>
            ) : null}

            {rightChip ? (
              <Box position={"absolute"} top={5} right={5} padding={1}>
                <Chip label={rightChip.split(",")[0].trim()} color={"secondary"} />
              </Box>
            ) : null}
          </CardMedia>

          <Typography variant={"h6"} sx={{ fontWeight: 700 }} alignSelf={"center"}>
            {title.split(",")[0].trim()}
          </Typography>
          <Typography
            variant={"caption"}
            sx={{ fontWeight: 500, paddingBottom: "10px" }}
            alignSelf={"center"}
            color={"text.secondary"}
          >
            {subtitle}
          </Typography>

          {leftButtonUrl ? (
            <Box
              padding={0}
              borderRadius={4}
              position={"absolute"}
              bottom={0}
              left={5}
              sx={{ cursor: "pointer" }}
            >
              <Button
                href={leftButtonUrl}
                target="_self"
                margin={0}
                startIcon={
                  <Box
                    component={"svg"}
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    width={24}
                    height={24}
                  >
                    <g transform="scale(-1, 1) translate(-24, 0)">
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth={2}
                        d="M17 8l4 4m0 0l-4 4m4-4H3"
                      />
                    </g>
                  </Box>
                }
              >
                <Typography
                  variant={"caption"}
                  sx={{ fontWeight: 400 }}
                  alignSelf={"center"}
                  color={"text.secondary"}
                >
                  {leftButtonTitle}
                </Typography>
              </Button>
            </Box>
          ) : null}

          {rightBbuttonUrl ? (
            <Box padding={0} borderRadius={4} position={"absolute"} bottom={0} right={5}>
              <Button
                href={rightBbuttonUrl}
                target="_blank"
                endIcon={
                  <Box
                    component={"svg"}
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    width={24}
                    height={24}
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth={2}
                      d="M17 8l4 4m0 0l-4 4m4-4H3"
                    />
                  </Box>
                }
              >
                <Typography
                  variant={"caption"}
                  sx={{ fontWeight: 400 }}
                  alignSelf={"center"}
                  color={"text.secondary"}
                >
                  {rightButtonTitle}
                </Typography>
              </Button>
            </Box>
          ) : null}
        </Box>
      </Box>
    </Grid>
  );
};
