import * as React from "react";

export const GretaHarperHeroVideo = () => {
  return (
    <div
      id="HeroVideo"
      style={{
        borderRadius: 8,
        float: "right",

        marginTop: "8px",
        position: "relative",
        maxHeight: "470px",
        minHeight: "320px",
        width: "50%",
        marginLeft: "20px",
        marginBottom: "20px",
      }}
    ></div>
  );
};
