import React from "react";
import PropTypes from "prop-types";
import { Drawer, Box } from "@mui/material";
import { SidebarNav } from "./components";

const Sidebar = ({ pages, open, variant, onClose }) => {
  const handleClose = () => onClose();

  return (
    <Drawer
      anchor="left"
      onClose={handleClose}
      open={open}
      variant={variant}
      sx={{
        "& .MuiPaper-root": {
          width: "100%",
          maxWidth: 280,
        },
      }}
    >
      <Box
        sx={{
          height: "100%",
          padding: 1,
        }}
      >
        <SidebarNav pages={pages} handleClose={handleClose} />
      </Box>
    </Drawer>
  );
};

Sidebar.propTypes = {
  onClose: PropTypes.func,
  open: PropTypes.bool.isRequired,
  variant: PropTypes.string.isRequired,
  pages: PropTypes.array.isRequired,
};

export default Sidebar;
