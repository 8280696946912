import React, { lazy, useState, Suspense } from "react";
import { Outlet, useLocation } from "react-router-dom";
import PropTypes from "prop-types";
import { useTheme } from "@mui/material/styles";
import { useMediaQuery, Box, AppBar } from "@mui/material";
import { Loading } from "./components";
import { setCanonicalLink } from "../../utils/header";
import { Container, HeroVideo } from "../../components";

import pages, { kristallMenu } from "../navigation";

const Footer = lazy(() => import("./components/Footer"));
const Sidebar = lazy(() => import("./components/Sidebar"));
const Topbar = lazy(() => import("./components/Topbar"));

const Main = ({ bgcolor = "transparent" }) => {
  const location = useLocation();
  const theme = useTheme();
  const isFirstPage = location.pathname === "/";

  const isMd = useMediaQuery(theme.breakpoints.up("md"), {
    defaultMatches: true,
  });

  const [openSidebar, setOpenSidebar] = useState(false);

  const handleSidebarOpen = () => {
    setOpenSidebar(true);
  };

  const handleSidebarClose = () => {
    setOpenSidebar(false);
  };

  const open = isMd ? false : openSidebar;

  if (isFirstPage) {
    document.title = "Innan Det Är Försent - Ta Vara På Det";
    setCanonicalLink("/");
  }

  return (
    <Box>
      <AppBar
        position="absolute"
        sx={{
          py: "20px",
          px: { sm: 0, md: "20px" },
          backgroundColor: bgcolor,
        }}
        elevation={0}
      >
        <Container paddingY={1}>
          <Suspense fallback={<></>}>
            <Topbar onSidebarOpen={handleSidebarOpen} pages={pages} colorInvert={false} />
          </Suspense>
        </Container>
      </AppBar>
      <Suspense fallback={<></>}>
        <Sidebar onClose={handleSidebarClose} open={open} variant="temporary" pages={pages} />
      </Suspense>
      <main>
        {isFirstPage ? (
          <Box mb={8}>
            <HeroVideo
              // videoSrc={
              //   'https://storage.googleapis.com/harper/gretaharper/utforska.dittandetag.se/main/intro-720.mp4'
              // }
              heroMedia={
                <Box height={{ xs: "100%", md: "97%" }} width="100%">
                  <Loading />
                </Box>
              }
            />
          </Box>
        ) : null}
        {<Outlet />}
      </main>
      <Container paddingY={4}>
        <Suspense fallback={<></>}>
          <Footer pages={kristallMenu} />
        </Suspense>
      </Container>
    </Box>
  );
};

Main.propTypes = {
  children: PropTypes.node,
  colorInvert: PropTypes.bool,
  bgcolor: PropTypes.string,
  disableFooter: PropTypes.bool,
};

export default Main;
