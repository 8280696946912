import React from "react";
import PropTypes from "prop-types";
import Snackbar from "@mui/material/Snackbar";
import "./styles.css";

const SnackbarAlert = ({ message = "", isOpen, handleClose, action, className = "" }) => {
  return (
    <Snackbar
      className={className}
      severity="success"
      sx={{
        width: {
          md: 870,
          xs: "90%",
        },
        margin: "auto",
        fontSize: "9px",
      }}
      open={isOpen}
      autoHideDuration={6000}
      onClose={handleClose}
      message={message}
      action={action}
      anchorOrigin={{ horizontal: "center", vertical: "bottom" }}
    ></Snackbar>
  );
};

SnackbarAlert.propTypes = {
  message: PropTypes.string,
  isOpen: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  action: PropTypes.node.isRequired,
  className: PropTypes.string,
};

export default SnackbarAlert;
