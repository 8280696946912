import React from "react";
import "./loading.css";

const Loading = () => {
  return (
    <div className="loading-Container">
      <div className="loading-circle">
        <div className="loading-transparentBorder">
          <div className="loading-progress"></div>
          <div className="loading-loadText">
            Ditt Andetag
            <div>Loading...</div>
          </div>
        </div>
      </div>
      <div className="loading-blur"></div>
    </div>
  );
};

export default Loading;
