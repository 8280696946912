import React from "react";
import { useTheme } from "@mui/material/styles";
import { Box } from "@mui/material";
import ScrollBelowArrow from "../ScrollBelowArrow";

const Hero = ({ backgroundImage = "", centerHero = false, fullSize = false, children }) => {
  const theme = useTheme();
  const isDark = theme.palette.mode === "dark";

  return (
    <>
      {fullSize ? <ScrollBelowArrow /> : null}
      <Box
        id="agency__portfolio-item--js-scroll"
        position={"relative"}
        display={"flex"}
        alignItems={"center"}
        marginTop={0}
        paddingTop={0}
        height={fullSize ? { xs: "85vh", md: "93vh" } : { xs: "43vh", md: "43vh" }}
        backgroundColor={isDark ? "#000000" : "#dcdcdc"}
      >
        <Box
          sx={{
            position: "absolute",
            objectFit: "cover",
            /* support for plugin https://github.com/bfred-it/object-fit-images */
            fontFamily: "object-fit: cover;",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            zIndex: 0,
            backgroundRepeat: "no-repeat",
            backgroundSize: "cover",
            backgroundPositionY: "bottom",
            backgroundPositionX: centerHero ? "center" : { xs: "90%", sm: "80%", md: "center" },
            backgroundImage: `linear-gradient(to left, rgba(245, 246, 252, 0), rgba(117, 19, 93, 0.4)), ${backgroundImage}`,
          }}
        />
        <Box
          id="hero-text"
          maxWidth={{ sm: 720, md: 1236 }}
          width={1}
          margin={"0 auto"}
          paddingX={{ xs: 3, md: 10 }}
          paddingTop={{ xs: 8, md: 15 }}
        >
          {children}
        </Box>
        {/* The curve at the bottom of the hero */}
        <Box
          id="curve"
          sx={{
            position: "absolute",
            left: 0,
            right: 0,
            top: fullSize ? { xs: "78vh", md: "86vh" } : { xs: "36vh", md: "36vh" },
            height: "10vh",
            width: "100%",
          }}
        >
          <Box
            component={"svg"}
            preserveAspectRatio="none"
            xmlns="http://www.w3.org/2000/svg"
            x="0px"
            y="0px"
            viewBox="0 0 1920 100.1"
            sx={{
              position: "absolute",
              bottom: 0,
              width: "100%",
              height: "100%",
            }}
          >
            <path
              fill={theme.palette.background.default}
              d="M0,0c0,0,934.4,125,1920,0v100.1H0L0,0z"
            ></path>
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default Hero;
