import * as React from "react";
import { Box } from "@mui/material";

export const Link = (props) => {
  if (!props.href.includes("http")) return <LinkSelf {...props} />;
  return <LinkBlank title={props.children} href={props.href} />;
};

export const LinkSelf = (props) => {
  return <Box component="a" color="#3399FF" sx={{ textDecoration: "none" }} {...props} />;
};

export const LinkBlank = ({ title, href }) => {
  return (
    <Box component="a" target="_blank" color="#3399FF" sx={{ textDecoration: "none" }} href={href}>
      {title}
    </Box>
  );
};
