import React from "react";
import { Box, Grid, Typography, Link } from "@mui/material";

export const ShowcaseGrid = ({ children }) => {
  return (
    <Box sx={{ marginTop: 6, marginBottom: 6 }}>
      <Grid container spacing={{ xs: 2, md: 4 }} justifyContent="center">
        {children}
      </Grid>
    </Box>
  );
};

export const ShowcaseGridItem = ({ title, description, alt, href, image }) => {
  return (
    <Grid item xs={12} sm={6} md={4}>
      <Box width={1} height={1} position={"relative"}>
        <img
          src={image}
          alt={alt}
          loading="lazy"
          style={{
            filter: "brightness(0.7)",
            borderRadius: 8,
            width: "100%",
            height: "100%",
            objectFit: "cover",
          }}
        />
        <Box position={"absolute"} bottom={0} left={0} right={0} width={1} padding={2} zIndex={2}>
          <Typography color={"common.white"} fontWeight={700} variant={"h6"}>
            {title}
          </Typography>
          <Typography color={"common.white"}>{description}</Typography>
          <Link
            href={href}
            color={"common.white"}
            fontWeight={700}
            sx={{ marginTop: 2, display: "block" }}
          >
            Triggla min fantasi
          </Link>
        </Box>
      </Box>
    </Grid>
  );
};
