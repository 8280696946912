/* Helper to set the Canonical Link in <head>
 */

export function setCanonicalLink(path) {
  const canonicalUrl = `https://utforska.dittandetag.se${path}`;
  const existingLinkTag = document.querySelector('link[rel="canonical"]');

  if (existingLinkTag) {
    existingLinkTag.href = canonicalUrl; // Update the href attribute
  } else {
    const linkTag = document.createElement("link");
    linkTag.setAttribute("rel", "canonical");
    linkTag.href = canonicalUrl;
    document.head.appendChild(linkTag); // Append the new link tag
  }
}
