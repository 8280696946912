import React from "react";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import { Box, Button } from "@mui/material";
import { Menu as MenuIcon } from "@mui/icons-material";
import NavLink from "./components/NavLink";
import lightLogo from "../../../../../assets/img/DittAndetagWhiteShadow.webp";
import { setChatVisibility } from "../../../../utils";

const Topbar = ({ onSidebarOpen }) => {
  const HamburgerMenu = () => (
    <Box sx={{ display: { sm: "block", md: "none" } }}>
      <Button
        onClick={() => onSidebarOpen()}
        color="primary"
        aria-label="Menu"
        sx={{
          px: 0,
          fontSize: 23,
          color: "#fff",
          maxWidth: "35px",
          maxHeight: "35px",
          minWidth: "35px",
          minHeight: "35px",
          filter: "drop-shadow(0px 0px 1px rgb(0 0 0))",
        }}
      >
        <MenuIcon fontSize="inherit" />
      </Button>
    </Box>
  );

  const Logo = () => (
    <Box
      display={"flex"}
      component={Link}
      to="/#welcome"
      onClick={() => setChatVisibility("hidden")}
      title="Ditt Andetag"
      width={{ xs: 100, md: 120 }}
      mr={4}
    >
      <Box
        component={"img"}
        alt="Ditt Andetag Logo"
        sx={{
          position: "relative",
          top: 3,
          right: { xs: 12, margin: "0 12px" },
        }}
        src={lightLogo}
        height={1}
        width={1}
      />
    </Box>
  );

  const NavLinks = () => (
    <Box sx={{ display: { xs: "none", md: "flex" }, gap: 4 }}>
      <NavLink title="Andlig utveckling" href="/kristaller/andlig-utveckling/" />
      <NavLink title="Kristaller" href="/kristaller/kristaller/" />
      <NavLink title="Ritualer" href="/kristaller/ritualer/" />
      <NavLink title="Event" href="/information/event/" />
    </Box>
  );

  const ToWebShop = () => (
    <Button
      variant="container"
      size="small"
      component={Link}
      to="https://dittandetag.se"
      onClick={() => setChatVisibility("visible")}
      color={"primary"}
      sx={{
        backgroundColor: "rgba(0, 0, 0, 0.4)",
        whiteSpace: "nowrap",
        minWidth: "max-content",
        textShadow: "#000000  0px 0px 7px",
      }}
    >
      Till Butiken
    </Button>
  );

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        width: 1,
      }}
    >
      <Box sx={{ display: "flex", align: "left", alignItems: "center", gap: 3 }}>
        <HamburgerMenu />
        <Logo />
        <NavLinks />
      </Box>
      <Box
        sx={{
          display: "flex",
          align: "right",
          alignItems: "center",
          gap: { xs: 1, md: 4 },
        }}
      >
        <ToWebShop />
      </Box>
    </Box>
  );
};

Topbar.propTypes = {
  onSidebarOpen: PropTypes.func,
};

export default Topbar;
