import * as React from "react";
import { Box, Grid, Typography, Card, Button } from "@mui/material";

export const Events = ({ children }) => {
  return (
    <Grid container spacing={4}>
      {children}
    </Grid>
  );
};

export const Event = ({ title, subtitle, comment, place, dates, times, eventUrl }) => {
  const timesArr = times ? times.split(",") : [];

  return (
    <Grid item xs={12} sm={6} md={4} mt={4}>
      <Box
        display={"block"}
        width={1}
        height={1}
        sx={{
          "textDecoration": "none",
          "transition": "all .2s ease-in-out",
          "&:hover": {
            transform: "translateY(-4px)",
          },
        }}
      >
        <Box
          component={Card}
          padding={4}
          width={1}
          height={1}
          display={"flex"}
          flexDirection={"column"}
        >
          <Typography variant={"h6"} gutterBottom sx={{ fontWeight: 700 }}>
            {title}
          </Typography>
          <Typography variant={"body1"} gutterBottom sx={{ fontWeight: 400 }}>
            {subtitle}
          </Typography>
          <Typography color="text.secondary">{place}</Typography>
          <Typography color="text.secondary">{dates}</Typography>
          {timesArr.map((time, i) => (
            <Typography color="text.secondary" key={i}>
              {time}
            </Typography>
          ))}
          <Typography color="text.secondary">{comment}</Typography>
          <Box sx={{ flexGrow: 1 }} />
          {eventUrl ? (
            <Box marginTop={2} display={"flex"} justifyContent={"flex-end"} alignSelf={"flex-end"}>
              <Button
                href={eventUrl}
                target="_blank"
                endIcon={
                  <Box
                    component={"svg"}
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    width={24}
                    height={24}
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth={2}
                      d="M17 8l4 4m0 0l-4 4m4-4H3"
                    />
                  </Box>
                }
              >
                Till Event
              </Button>
            </Box>
          ) : null}
        </Box>
      </Box>
    </Grid>
  );
};
